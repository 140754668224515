<template>
	<div class="custom-sticker pb-10" :class="$vuetify.breakpoint.smAndDown && tab === 1 && 'overflow-visible'">
		<v-card>
			<v-card-text class="py-3">
				<h2 class="white--text fs--25 fw--700">Stickers</h2>
			</v-card-text>
		</v-card>

		<v-tabs v-model="tab" slider-color="pink" color="white" background-color="transparent">
			<v-tab v-for="tab in tabs" :key="tab.key">
				<img :src="tab.icon" width="25" :alt="tab.name" />
				<span class="pl-3 fw--400">{{ tab.name }}</span>
				<v-chip
					v-if="tabBadges[tab.name] && tabBadges[tab.name] > 0"
					color="pink"
					class="px-2 ml-3"
					rounded
					small>
					{{ tabBadges[tab.name] }}
				</v-chip>
				<v-chip v-if="tab.tag" color="pink" class="px-2 ml-2 text-capitalize" rounded small>
					{{ tab.tag }}
				</v-chip>
			</v-tab>
		</v-tabs>
		<v-divider class="black"></v-divider>

		<v-tabs-items v-model="tab">
			<v-tab-item v-for="tab in tabs" :key="tab.index">
				<StickerTab
					:stickerType="tab.key"
					:isExtensionOnline="isExtensionOnline"
					@onOpenUploadWizard="actions.openWizard"
					:key="refreshKey" />
			</v-tab-item>
		</v-tabs-items>

		<StickerUploader
			v-if="uploadWizard"
			:uploader="uploadWizard"
			:stickerType="stickerType"
			@onClose="uploadWizard = false"
			@onUploaded="actions.onUploadComplete" />

		<UploadComplete v-if="uploaded" :complete="uploaded" :type="stickerType" @close="uploaded = false" />

		<GenericPopup
			v-if="showAvailableSlotsDialog"
			:modal="showAvailableSlotsDialog"
			@close="showAvailableSlotsDialog = false">
			<template #primaryHeader>Sticker Slots Available</template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					You have available sticker slots! Look for the count on each sticker tab for available slots.
				</p>
			</template>
		</GenericPopup>
	</div>
</template>

<script>
import axios from '@axios';
import { onBeforeUnmount, reactive, ref, watch, watchEffect } from '@vue/composition-api';
import { useRouter } from '@core/utils';
import { StickerType } from '../../utils/constants';
import { getExtensionStatus } from '../../utils/sticker';
import { MessageEnum } from '../../utils/constants';
import StickerTab from './stickers/StickerTab.vue';
import StickerUploader from './stickers/wizard/StickerUploader.vue';
import UploadComplete from './stickers/UploadComplete.vue';
import GenericPopup from '@/components/GenericPopup';

export default {
	components: {
		StickerTab,
		StickerUploader,
		UploadComplete,
		GenericPopup,
	},
	setup() {
		const tab = ref(0);
		const uploadWizard = ref(false);
		const edit = ref(false);
		const uploaded = ref(false);
		const stickerType = ref('CUSTOM');
		const stickerSlot = ref(0);
		const refreshKey = ref(1);
		const isExtensionOnline = ref(false);
		const stickerToEdit = ref(null);

		const isTTSValidated = ref(true);
		const showPasswordBox = ref(false);
		const showAvailableSlotsDialog = ref(false);

		const Router = useRouter();
		const { router } = useRouter();

		const tabs = [
			{
				index: 0,
				name: 'custom',
				icon: require('@/assets/icons/Custom Stickers.svg'),
				key: StickerType.CUSTOM,
				tab: 'custom'
			},
			{
				index: 1,
				name: 'reward',
				icon: require('@/assets/icons/Reward Stickers.svg'),
				key: StickerType.REWARD,
				tab: 'reward'
			},
			{
				index: 2,
				name: 'global',
				icon: require('@/assets/icons/Global Stickers.svg'),
				key: StickerType.GLOBAL,
				tab: 'global'
			},
		];

		// Set tab from query string.
		const queryTab = Router.route.value.query.tab || '';
		if (queryTab && queryTab.length > 0) {
			const query = tabs.find(tab => tab.key === queryTab);
			if (query) {
				tab.value = query.index;
			} else {
				tab.value = 0;
			}
		}

		// TODO implement badges.
		// Badges are not used on the global tab, but may be in the future.
		const tabBadges = reactive({
			custom: 0,
			reward: 0,
			power: 0,
			global: 0,
			tts: 0,
		});

		// Handle WS events.
		const eventBus = require('js-event-bus')();
		eventBus.on('ws-message', function (payload) {
			if (payload.method == MessageEnum.EXTENSION_STATUS) {
				isExtensionOnline.value = payload.value;
			} else if (payload.method == MessageEnum.EXTENSION_CO_HOST_STATUS) {
				// The get extension status calls returns TTS slot details and sets it to the store.
				getExtensionStatus();
			} else if (payload.method == MessageEnum.STICKER_OPEN_SLOT_CHANGE) {
				actions.loadTabBadges();
			}
		});

		onBeforeUnmount(() => {
			eventBus.detachAll();
		});

		watch(tab, val => {
			const path = `/stickers?tab=${tabs[tab.value].tab}`
            const routePath = Router.route.value.fullPath;

            if (path !== routePath) {
                router.replace({ name: 'streamer-stickers', query: { tab: tabs[tab.value].tab } })
            }
		});

		watchEffect(() => {
			const query = Router.route.value.query;
			if (query) {
				const qTab = query.tab;

				if (qTab) {
					const queryTab = tabs.find(tab => tab.tab === qTab);
					if (queryTab) {
						tab.value = queryTab.index;
					} else {
						tab.value = 0;
					}
				} else {
					router.replace({ name: 'streamer-stickers', query: { tab: tabs[tab.value].tab } })
				}
			}
		});

		const actions = {
			loadTabBadges: function () {
				axios.get('/custom-sticker/open-slot-count').then(res => {
					tabBadges.custom = res.data.find(d => d.stickerType == StickerType.CUSTOM).count;
					tabBadges.reward = res.data.find(d => d.stickerType == StickerType.REWARD).count;

					// Check if slot available
					if (Router.route.value.query.available) {
						showAvailableSlotsDialog.value = true;
						router.push({ name: 'streamer-stickers' });
					}
				});
			},
			openWizard: function (data) {
				stickerType.value = data.type;
				stickerSlot.value = data.stickerSlot;
				
				uploadWizard.value = true;
			},
			openEditWizard: function (data) {
				stickerType.value = data.type;
				stickerSlot.value = data.stickerSlot;

				edit.value = true;
				stickerToEdit.value = JSON.parse(JSON.stringify(data.sticker));

				uploadWizard.value = true;
			},
			onUploadComplete: function (value) {
				uploadWizard.value = false;
				uploaded.value = value;
				refreshKey.value = refreshKey.value + 1;
			},
		};

		// Get extension status on initial load.
		getExtensionStatus(data => {
			isExtensionOnline.value = data.online;
		});

		// Load data.
		actions.loadTabBadges();

		return {
			actions,
			isExtensionOnline,
			tab,
			tabs,
			tabBadges,
			uploadWizard,
			isTTSValidated,
			showPasswordBox,
			edit,
			stickerToEdit,
			uploaded,
			stickerType,
			stickerSlot,
			StickerType,
			refreshKey,
			showAvailableSlotsDialog,
		};
	},
};
</script>
